export const metadata = {
  title: 'Fernando de Matos Araujo -Full Stack Developer / Software Engineer',
  description:
    'I am a passionate c# web developer who is always ready for new challenges. Seeing the satisfaction on the stakeholders and clients faces makes me grateful for one more day of work. Self-Taught Software Engineer / Web developer skilled in C# | MVC | Asp.Net |JavaScript | JQuery |Bootstrap |RESTful API',
  keywords: [
    'Fernando de Matos Araujo, Title:Full Stack Developer, Title:Software Engineer,Language:C#, Framework:MVC, Framework:ASP.NET MVC, Library:Jquery, Framework:BootStrap,  Framework:Entity Framework, Library:React, Library:Next.js, Framework:Node.js, Language:TypeScript, Language:JavaScript, Database:SQl Server, Database:MongoDB, Database:MySQL,Platform:Docker, Automation:Jenkins, Version Control:Git, Version Control:GitHub, Version Control:GitLab, Version Control:Bitbucket, CI/CD, Methodology:Agile, Scrum, Kanban, TDD, Clean Code, SOLID, Design Patterns, Clean Architecture, Microservices, Serverless, OAuth, JWT, BDD, DDD SOLID, Design Patterns, Clean Architecture, Microservices, Serverless, REST, REST API,  Canada, USA',
  ],
  referrer: 'origin-when-cross-origin',
  colorScheme: 'light',
  category: 'technology',
  themeColor: '#000000',
  manifest: 'https://nextjs.org/manifest.json',
  viewport: {
    width: 'device-width',
    height: 'device-height',
    initialScale: '1.00',
    maximumScale: '1.0',
  },
  verification: {
    google: '_-UTTe-6_hfOXqUJP4ljARyKHX6z4buPEQsps-tYEHM',
    yandex: 'yandex',
    yahoo: 'yahoo',
  },
  robots: {
    index: true,
    follow: true,
    nocache: true,
    googleBot: {
      index: true,
      follow: true,
      noimageindex: true,
      'max-snippet': -1,
      'max-video-preview': -1,
      'max-image-preview': 'large',
    },
  },
  icons: {
    icon: '%PUBLIC_URL%/favicon.ico',
    shortcut: '/shortcut-icon.png',
    apple: '%PUBLIC_URL%/favicon.ico',
  },
};
